.Software {
  text-align: center;
  font-family: 'swashr';
  font-size: x-large;
}
.Chique {
  text-align: center;
  font-family: 'swashr';
  font-size: xx-large;
  line-height: 0%;
}
.Subtitle {
  text-align: center;
  font-family: 'bookman';
  font-size: large;
}